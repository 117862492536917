
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.App p,.App h1,.App h2,.App h3,.App h4,.App h5,.App h6{
font-family: 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
color: #5a5959;
}

.App {
text-align: center;
}
*{
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline
}
section{
  overflow: hidden;
}
.App-header nav {
display: block;
transition: all 0.3s ease-in-out;
/*box-shadow: 0px 2px 28px #797979b0;*/
}
nav .nav-link{
color: white;
}
.navClass{
background-color: white;
display: block;
box-shadow: 0px 2px 28px #797979b0;
transition: all 0.3s ease-in-out;
}
nav.navClass .nav-link{
color: rgb(68, 68, 68);
}
nav ul li{
text-decoration: none;
list-style: none;
}
.App-header .container-fluid{
padding: 0 4vw;
}
.navbar-toggler.white{
border: 2px solid white !important;
background-color: transparent;
border: 1px solid transparent;
}
.navbar-toggler:focus{
outline: none !important;
border: 2px solid white !important;
}
.navbar-light .blanco{
background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%271 0 29 31%27%3e%3cpath stroke=%27white%27 stroke-linecap=%27round%27 stroke-miterlimit=%2722%27 stroke-width=%275%27 d=%27M5 7h22M5 15h22M4 24h24%27/%3e%3c/svg%3e) !important;
}

.App-header .container-fluid .navbar-brand{
padding: 0;
margin: 0;
width: 12rem;
}
.App-header .container-fluid .navbar-brand img{
width: 95%;
}
.App-header .navbar-expand-lg .navbar-collapse{
justify-content: center;
}
.App-header .navbar-expand-lg .nav-pills {
flex-direction: row;
justify-content: flex-start;
width: 85%;
}
.App-header .navbar-expand-lg .nav-pills .nav-item {
width: auto;
margin: auto 1rem;
}
.mostrar{
display: block !important;
}
.redsocialMob{
display: none;
}
.conteLinked{
display: block;
}
.linkeicon{
  font-size: 23px;
}
@media(max-width:991px){
.redsocialMob{
display: block;
}
.conteLinked{
display: none;
}
}
.App-header .portada{
height: 94vh;
background-color: white;
background-size: cover;
background-attachment: scroll;
background-position: bottom;
display: flex;
justify-content: center;
align-items: center;
}
.App-header .portada h1{
color: white;
border: 1px solid;
padding: 21px;
border-radius: 5px;
background-color: #010a1fbd;
margin-top: -2.5rem;
}
.App .container{
max-width: 84rem;
}
@media (max-width: 1400px){
.App .container {
max-width: 85%;
}
}
@media (max-width: 770px){
  .App-header .portada{
    height: 95vh;
    background-image: url(https://franquiciarg.com.ar/imagenes/portada_mob.webp) !important;
    }
}
.seccion_uno {
height: auto;
background-color: white;
margin-top: -3rem;
padding-bottom: 3vw;
}
.seccion_uno .title{
font-size: 2.5rem;
color: #1b4d76;
padding-top: rem;
}
.seccion_uno hr{
opacity: .79;
border-bottom: 4px solid #1b4d76;
width: 17%;
margin: 0;
border-radius: 9px;
position: relative;
left: 49%;
}
.seccion_uno .contePes{
padding-top: 5rem;
}
.seccion_uno  p{
text-align: left;
width: 78%;
margin: 3rem auto;
font-size: 1.32rem;
line-height: 1.82rem;
color: #5b5757;
}
.seccion_uno .contePes p:nth-child(2){
padding-top: 2rem;
}
.seccion_uno img{
width: 39%;
}
.contBtn{
background-color: #003178;
width: 8rem;
margin: 3rem auto;
color: white;
padding: .4rem 1rem;
transition: all .3s ease-in-out;
}
.contBtn:hover{
background-color: rgb(231, 231, 231);
color: #003178;
}
.contBtn:focus{
background-color: #0a60e0;
color: white;
}


.seccion_dos {
height: auto;
background-color: #f6f6f6a9;
display: flex;
align-items: center;
padding: 7rem 0;
background-size: cover;
background-position: top;
}
.seccion_dos .container{
margin-top:-6rem;
}
.seccion_dos .conteDos{
padding-top: 5vw;
}
.seccion_dos .conteDos .row{
align-items: center;
}
.seccion_dos .conteDos .row:nth-child(2){
margin: 8rem auto;
}
.seccion_dos .conteDos .row:nth-child(1) .col-6:nth-child(1){
text-align: right;
padding-right: 2rem;
}
.seccion_dos .conteDos .row:nth-child(1) .col-6:nth-child(1) p{
width: 90%;
margin: 1rem 0 1rem auto;
}
.seccion_dos .conteDos .row:nth-child(2) .col-6:nth-child(2){
text-align: left;
padding-left: 2rem;
}
.seccion_dos .conteDos .row:nth-child(2) .col-6:nth-child(2) p{
width: 90%;
margin: 1rem auto 1rem 0;
}
.seccion_dos .conteDos .row:nth-child(3) .col-6:nth-child(1){
text-align: right;
padding-right: 2rem;
}
.seccion_dos .conteDos .row:nth-child(3) .col-6:nth-child(1) p{
width: 90%;
margin: 1rem 0 1rem auto;
}
.seccion_dos .title{
font-size: 2.5rem;
color: #1b4d76;
padding-top: 5rem;
}
.seccion_dos hr{
opacity: .79;
border-bottom: 4px solid #1b4d76;
width: 10%;
margin: 0;
border-radius: 9px;
position: relative;
left: 49%;
}
.seccion_dos h5{
font-size: 1.45rem;
}
.seccion_dos p {
margin-top: 0;
margin-bottom: 1rem;
}
.primerImagen img{
width: 100%;
opacity: .79;
}
.segundaImagen img{
width: 100%;
opacity: .79;
}
.tercerImagen img{
width: 100%;
opacity: .79;
}

.seccion_tres {
height: auto;
background-color: white;
padding: 3vw 0;
}
.seccion_tres a{
  text-decoration: none;
}
.seccion_tres .title{
font-size: 2.5rem;
color: #1b4d76;
padding-top: 5rem;
}
.seccion_tres hr{
opacity: .79;
border-bottom: 4px solid #1b4d76;
width: 12%;
margin: 0;
border-radius: 9px;
position: relative;
left: 49%;
margin-bottom: 4rem;
}
.seccion_tres h5{
font-size: 1.45rem;
text-align: left;
}
.seccion_tres .swiffy-slider{
  margin: 2rem auto;
}
.seccion_tres .slider-container{
  grid-auto-rows: 82% !important;
  grid-auto-columns: 39% !important;
  grid-gap: 0rem !important;
}
.seccion_tres .slider-container>*{
  width: 88% !important;
}
.seccion_tres .slider-container img{
  max-width: 70%;
  height: auto !important;
}
.seccion_tres .slider-nav-dark .slider-nav {
  opacity: .46 !important;
}
.seccion_tres .slider-nav-dark .slider-nav:hover{
  opacity: .96 !important;
}
.seccion_tres .slider-nav::after{
  width: 1.65rem !important;
    height: 1.65rem !important;
    background-repeat: no-repeat !important;
}
.seccion_tres .slider-container h4{
  margin: 0;
  font-size: 0.91rem;
}

.seccion_cuatro {
height: auto;
background-color: #f9f9f9;
padding:0;
overflow: hidden;
}
.seccion_cuatro  .col-3{
align-content: center;
background-color: #1548a5;
color:white;
}
.seccion_cuatro .col-3 div h4{
margin-top:-16rem;
font-size: 1.7rem;
color: white;
}

.seccion_cuatro  .col-3 div{
text-align: left;
width: 91%;
margin: auto;
}
.seccion_cuatro  .col-3 div:nth-child(2){
padding-top: 2rem;
}
.seccion_cuatro  .col-3 div p{
margin: 0;
padding: 0;
}
.seccion_cuatro .col-3 div:nth-child(2) p{
padding: 5%;
background-color: #4090d4;
color:white;
transition: all .3s ease-in-out;
}
.seccion_cuatro .col-3 div:nth-child(2) p:hover{
background-color: #2970ab;
color:white;
}
.seccion_cuatro  .col-3 div:nth-child(2) a:nth-child(2) p{
margin: 2rem auto;
}
.seccion_cuatro  .col-3 a{
position: relative;
color:white;
text-decoration: none;
}
.seccion_cuatro  .col-3 svg{
color:white;
font-size: 33px;
padding-right: 0.51rem;
}
.seccion_cuatro .conteTitu{
padding: 5.6rem 0 3rem 0;
}
.seccion_cuatro .title{
font-size: 2.5rem;
color: #1b4d76;
margin-left: 33%;
}
.seccion_cuatro hr{
opacity: .79;
border-bottom: 4px solid #1b4d76;
width: 9%;
margin: 0;
border-radius: 9px;
position: relative;
left: 67%;
}
@media(max-width:1400px){
.seccion_cuatro .col-3 div h4{
margin-top: -21rem; 
}
}
.seccion_cuatro .contForm{
max-width: 65%;
margin: auto;
}
.seccion_cuatro .contForm h4{
text-align: left;
margin-left: 6%;
}
.contForm form{
/*border: 2.5px solid #4181dc5e;
border-radius: 8px;*/
padding: 2rem 0 4rem 0;
}
.contForm form div.row{
display: flex;
}
.contForm form .row .col-6 div{
display: grid;
border: 1.5px solid #d2d2d294;
border-radius: 4px;
padding: 1rem;
margin: .93rem auto;
width: 80%;
}
.contForm form div label{
text-align: left;
color: #888888;
padding-bottom: .2rem;
}
.contForm form div input{
padding: 0.61rem 1rem;
color: #002e70;
border-radius: 4px;
outline: 2px solid #1b4d7600;
transition: all .3s ease-in-out;
}
.contForm form div input:hover{
outline: 2px solid #1b4d7636;
}
.contForm form div input:focus{
outline: 2px solid #1b4d76;
}
.contForm form div input::placeholder,
.contForm form div textarea::placeholder{
color: #41454b77;
font-size: 13px;
}
.contForm form .recapcha{
padding: 2rem 0;
}
.contForm form .recapcha, 
.contForm form .recapcha div {
border: none ;
margin: 0;
}
.contForm form .recapcha div {
position: relative;
left: .6rem;
margin: .4rem 0;
}
.contTxtArea{
display: grid;
border: 1.5px solid #d2d2d294;
border-radius: 8px;
padding: 1rem;
margin: .93rem auto;
width: 90%;
}
.contForm form textarea{
max-width: 100%;
min-width: 100%;
max-height: 15rem;
min-height: 15rem;
height: 215px;
padding: 0.61rem 1rem;
color: #002e70;
border-radius: 4px;
transition: all .3s ease-in-out;
}
.contForm form textarea:hover{
outline: 2px solid #1b4d7636;
}
.contForm form textarea:focus{
outline: 2px solid #1b4d76;
}
.contForm form .error{
color: #e13232;
margin: 0;
padding: 3px 0;
text-align: left;
font-size: 13px;
}
.contForm form .recapcha .error{
margin-left: 4.5%;
}
.contForm form button{
background-color: #1548a5;
color: white;
padding: 4px 12px;
transition: all .3s ease-in-out;
}
.contForm form button:hover{
background-color: rgb(218, 218, 218);
color: #1548a5;
padding: 4px 12px;
}
.contForm form button:focus{
background-color: #3f7ae7;
color: white;
padding: 4px 12px;
}
.seccion_cinco{
background-image: url(https://franquiciarg.com.ar/imagenes/contacto.jpg);
background-repeat: no-repeat;
background-color: white;
background-size: cover;
background-attachment: scroll;
background-position: top;
background-attachment: fixed;
height: 98vh;
display: flex;
justify-content: center;
align-items: center;
}
.seccion_cinco h3{
color: white;
font-size: 1.9rem;
}
.seccion_cinco h3 b{
font-weight: 800;
}
.seccion_cinco h4{
color: white;
font-size: 1.9rem;
padding-top: 3rem;
}
.seccion_cinco .row-column div:nth-child(2){
position: absolute;
left: 17%;
}

footer{
height: auto;
padding: 5vw 0;
background-color: #232323;
}
.logoFuter{
width: 98%;
}
.navFooter{
text-align: left;
margin-top: -.5rem;
}
.nav-futer{
padding: .84vw 0;
}
footer ul{
padding: 0;
}
footer ul li{
text-decoration: none;
list-style: none;
color: white;
}
footer a{
color: white;
text-decoration: none;
}
footer span{
color: white;
}
.conteDire{

}
.listaDire li{
padding-top:2vw;
}
.conteRsFooter li{
padding-top:2vw;
}
.posfuter {
background-color: #2e2b2be6;
}
.posfuter p {
color: grey;
margin: 0;
padding: 1rem 0;
font-size: 0.7rem;
}
/* ========================================= 
estilos del carrusel 
======================================= */

@keyframes scroll {
0% {
transform: translateX(5rem);
}
100% {
transform: translateX(-4350px);
}
0% {
transform: translateX(7rem);
}
}
.sliderEnNumeros {
background: white;
/*box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);*/
height: 160px;
margin: auto;
overflow: hidden;
position: relative;
width: 100%;
margin: 4rem auto;
}
.sliderEnNumeros::before, .sliderEnNumeros::after {
background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
content: "";
height: 170px;
position: absolute;
width: 150px;
z-index: 2;
}
.sliderEnNumeros::after {
right: 0;
top: 0;
transform: rotateZ(180deg);
}
.sliderEnNumeros::before {
left: 0;
top: 0; 
}
.sliderEnNumeros .slider_conte {
animation: scroll 183s linear infinite;
display: flex;
flex-wrap: nowrap;
width:  5500px;
}
.sliderEnNumeros .slideNumsItem {
height: 200px;
width: 250px;
}
.sliderEnNumeros .slideNumsItem img{
width: 91%; 
}
/* ========================================= 
estilos del carrusel 
======================================= */
.fraseMobile{
  display: none;
}
@media (max-width: 1200px){
.App-header .portada h1 {
padding: 21px 23px;
font-size: 2rem;
}
.seccion_uno .title,
.seccion_dos .title,
.seccion_tres .title,
.seccion_cuatro .title{
font-size: 1.97rem;
}
.seccion_uno p{
font-size: 1rem;
margin: 1rem auto;
}
.seccion_uno img {
margin: 3rem auto 1rem auto;
}
.seccion_dos h5 {
font-size: 1.2rem;
}
.seccion_dos .conteDos .row:nth-child(1) .col-6:nth-child(1) p,
.seccion_dos .conteDos .row:nth-child(2) .col-6:nth-child(2) p,
.seccion_dos .conteDos .row:nth-child(3) .col-6:nth-child(1) p{
width: 85%;
font-size: 0.8rem;
}
.sliderEnNumeros .slideNumsItem img {
width: 78%;
}
.sliderEnNumeros {
height: 109px;
margin: 3rem auto;
}
.seccion_cuatro .contForm h4 {
font-size: 1.23rem;
} 
.seccion_cuatro .col-3 div h4 {
font-size: 1.23rem;
}
.seccion_cuatro .col-3 div:nth-child(2) p {
font-size: 14px;
}
.seccion_cuatro .col-3 svg {
font-size: 20px;
}
.seccion_cuatro .contForm {
max-width: 85%;
}
.contForm form div label {
font-size: .91rem;
}
.contForm form div input {
padding: 0.41rem .31rem;
}
.contForm form div input::placeholder,
.contForm form div textarea::placeholder{
color: #41454b77;
font-size: 11px;
}
}
@media (max-width: 991px){
.App-header .portada h1 {
padding: 11px 10px;
font-size: 1.8rem;
}
.navbar-toggler{
margin-right: 5%; 
}

.App-header .container-fluid {
padding: 0vw;
}
.App-header .navbar-expand-lg .nav-pills {
flex-direction: column;
justify-content: center;
width: 100%;
}
.App-header .navbar-expand-lg .navbar-collapse {
background-color: #ffffffe3;
}
.nav-pills .nav-link {
  padding-top: 3vw;
  padding-bottom: 3vw;
  color: grey;
  font-weight: 600;
}
.linkeicon{
  font-size: 32px;
}
.seccion_uno .title, .seccion_dos .title, .seccion_tres .title, .seccion_cuatro .title {
font-size: 1.8rem;
}
.seccion_uno p {
font-size: .91rem;
margin: 1rem auto;
width: 95%;
} 
.seccion_uno img {
width: 25rem;
}
.seccion_dos .conteDos .row {
align-items: center;
flex-direction: column;
}
.seccion_dos .conteDos .row .col-6{
text-align: center !important;
max-width: 100%;
width: 100%;
} 
.seccion_dos h5 {
font-size: 1.1rem;
}
.seccion_dos .conteDos .row:nth-child(2) {
margin: 5rem auto;
flex-direction: column-reverse;
}
.seccion_dos .conteDos .row:nth-child(1) .col-6:nth-child(1) p, 
.seccion_dos .conteDos .row:nth-child(2) .col-6:nth-child(2) p, 
.seccion_dos .conteDos .row:nth-child(3) .col-6:nth-child(1) p {
width: 95%;
font-size: 0.8rem;
margin: 1rem auto;
text-align: left;
}
.seccion_dos .conteDos .row:nth-child(2) .col-6:nth-child(2) {
padding-left: 0;
}
.seccion_tres .App .container {
max-width: 85%;
}
.sliderEnNumeros {
height: 72px;
margin: 2rem auto;
}
.sliderEnNumeros .slider_conte{
width: 3500px;
}
.sliderEnNumeros .slideNumsItem {
height: 124px;
width: 177px;
}
.sliderEnNumeros .slideNumsItem img {
width: 80%;
}
.sliderEnNumeros::before, .sliderEnNumeros::after{
width: 65px;
}
.seccion_cuatro .row{
flex-direction: column;
}
.seccion_cuatro .col-9{
width: 100%;
}
.seccion_cuatro .title{
margin-left: 0rem;
}
.seccion_cuatro hr {
left: 57%;
}
.contForm form .row .col-6,
.seccion_cuatro .col-3{
width:100%;
}
.contForm form .row .col-6 div{
margin: 2rem auto;
width: 100%;
}
.contTxtArea {
width: 100%;
}
.seccion_cuatro .col-3 div h4 {
margin-top: 4rem;
}
.seccion_cuatro .col-3 div:nth-child(2) {
padding-top: 3rem;
padding-bottom: 6rem;
}
footer .row {
flex-direction: column;
}
footer .row .col-3{
width:100%;
}
.navFooter {
text-align: center;
margin-top: -.5rem;
}
.seccion_cinco{
height: auto;
padding: 17rem 0;
}
.logoFuter {
width: 70%;
}
}
@media (max-width: 770px){
.App .container {
max-width: 98%;
}
.nav-pills .nav-link {
  padding-top: 7vw;
  padding-bottom: 7vw;
}
.fraseMobile{
  display: block;
}
.seccion_uno img {
width: 75%;
}
.seccion_tres .slider-container img {
  max-width: 100%;
}
.seccion_tres .slider-container h4 {
  margin: 0;
  font-size: 0.89rem;
}
.seccion_tres .slider-container>*{
  width: 100% !important;
}
.seccion_tres .slider-nav{
  left: -0.81rem   ;
  display: none !important;
}
.seccion_tres .slider-nav-next{
  right: -0.61rem !important  ;
}
.seccion_tres .slider-container{
  margin: 0 1rem !important;
}
.logoFuter {
width: 55%;
}
.nav-futer {
padding: 3vw 0;
width: 70%;
margin: auto;
text-align: left;
}
.dtsUbic{
width: 70%;
margin: auto;
text-align: left;
}
.conteRsFooter{
padding: 1vw 0;
width: 70%;
margin: auto;
text-align: left;
}
}